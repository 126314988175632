import { PostHog } from "posthog-node"

import { env } from "@/env.mjs"

export const PosthogEvents = {
  PUBLISH_FLOW_NEW_PROPERTY_BUTTON_CLICK:
    "publish_flow:new_property_button_click",
  PUBLISH_FLOW_IMPORT_FACEBOOK_PROPERTIES_BUTTON_CLICK:
    "publish_flow:import_facebook_properties_button_click",
  PUBLISH_FLOW_REQUEST_IMPORT_FACEBOOK_PROPERTIES_SUBMIT:
    "publish_flow:request_import_facebook_properties_submit",
  PUBLISH_FLOW_TRY_TO_UPLOAD_IMAGES: "publish_flow:try_to_upload_images",
  PUBLISH_FLOW_PROPERTY_RECEIVED: "publish_flow:property_received",
  LANDING_PAGE_NEW_PROPERTY_BUTTON_CLICK:
    "landing_page:new_property_button_click",
  YOUR_PROPERTIES_PAGE_NEW_PROPERTY_BUTTON_CLICK:
    "your_properties:new_property_button_click",
  YOUR_PROPERTIES_PAGE_PROPERTY_FINISHED_CLICK:
    "your_properties:property_finished_click",
  BACKOFFICE_PROPERTY_STATUS_CHANGE: "backoffice:property_status_change",
  ORG_ADMIN_INVITATION_SENT: "org_admin:invitation_sent",
  CLERK_WEBHOOK_NEW_USER: "clerk_webhook:new_user",
  CONTACT_PROPERTY: "contact:property",
  CONTACT_ENTERPRISE: "contact:enterprise",
  CONTACT_LOGIN_WALL: "contact:login_wall",
  CONTACT_MANDATORY_PHONE_DIALOG_SHOWN: "contact:mandatory_phone_dialog:shown",
  CONTACT_MANDATORY_PHONE_DIALOG_SUBMIT:
    "contact:mandatory_phone_dialog:submit",
  UPSCALER_WAILIST_SIGNUP_SUBMIT: "upscaler:waitlist_signup_submit",
  FEED_SEARCH: "feed:search",
} as const

export default function PostHogClient() {
  const posthogClient = new PostHog(env.NEXT_PUBLIC_POSTHOG_KEY, {
    host: "https://eu.posthog.com",
    flushAt: 1,
    flushInterval: 0,
  })
  return posthogClient
}
